import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/node/work/src/templates/post.template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "what-is-lagranges-theorem"
    }}>{`What is Lagrange's Theorem?`}</h2>
    <p>{`Over the past year I've gone down a deep dive of cryptography of which group
theory is a pivotal dependency. One theorem I've seem that are leaned on
heavily when using popular cryptographic objects like finite fields is
Lagrange's Theorem.`}</p>
    <p>{`Simply put Lagrange's theorem states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If `}<span parentName="p" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span></span></span></span></span>{` is a subgroup of a finite group `}<span parentName="p" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`G`}</span></span></span></span></span>{`. Then the order of `}<span parentName="p" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span></span></span></span></span>{` divides the order
of `}<span parentName="p" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`G`}</span></span></span></span></span>{`.`}</p>
    </blockquote>
    <p>{`Or in terse mathematical notation:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mo parentName="mrow">{`≤`}</mo><mi parentName="mrow">{`G`}</mi><mo parentName="mrow">{`→`}</mo><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`o`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`r`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`d`}</mi></mrow><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`(`}</mo><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`)`}</mo></mrow><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{` `}</mtext><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`o`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`r`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`d`}</mi></mrow><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`(`}</mo><mi parentName="mrow">{`G`}</mi><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`)`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H \\leq G \\rightarrow {\\rm ord}\\left(H\\right) \\space | \\space {\\rm ord}\\left(G\\right)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8193em",
                  "verticalAlign": "-0.136em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≤`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`ord`}</span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`)`}</span></span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`ord`}</span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`G`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`)`}</span></span></span></span></span></span></div>
    <p>{`This a pretty elegant finding! Thanks Lagrange!`}</p>
    <p>{`To understand why this is true we'll go through four checkpoints.`}</p>
    <ol>
      <li parentName="ol">{`What is a coset?`}</li>
      <li parentName="ol">{`When are cosets the same?`}</li>
      <li parentName="ol">{`Why cosets are equal xor disjoint?`}</li>
      <li parentName="ol">{`Why cosets split the group?`}</li>
    </ol>
    <h2 {...{
      "id": "what-is-a-coset"
    }}>{`What is a coset?`}</h2>
    <p>{`While the word "coset" seems like a fancy unrelated term, but in reality it's
a very simple concept that will be core in proving Lagrange's Theorem.`}</p>
    <p>{`If `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span></span></span></span></span>{` is
a subgroup of
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span></span></span></span></span>{`, then a coset is the set of elements where
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`g`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`g`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span></span></span></span></span>{` is
applied on
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span></span></span></span></span>{` where
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`g`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`g`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span></span></span></span></span>{` is an element of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span></span></span></span></span>{`.`}</p>
    <p>{`In other words, the cosets of a subgroup `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span></span></span></span></span>{` can be thought of as applying the
group operation as a mapping function on the elements of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span></span></span></span></span>{` with the elements of
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span></span></span></span></span></p>
    <p>{`For a more terse math notation, cosets can be described like this:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`g`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`{`}</mo><mi parentName="mrow">{`g`}</mi><mi parentName="mrow">{`h`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi><mi parentName="mrow">{`h`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`}`}</mo></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H g = \\{ g h \\| h \\in H \\}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8778em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`{`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`h`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`h`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`}`}</span></span></span></span></span></div>
    <blockquote>
      <p parentName="blockquote">{`Note: There is a notion of "left" and "right" cosets, but for this post we'll
be assuming
`}<span parentName="p" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`G`}</span></span></span></span></span>{` is abelian.`}</p>
    </blockquote>
    <p>{`Let's make this a little more concrete with an example. Lets consider the
cosets of the group `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "double-struck"
                  }}>{`Z`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`/`}</mi><mn parentName="mrow">{`24`}</mn><mi parentName="mrow" {...{
                    "mathvariant": "double-struck"
                  }}>{`Z`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\Z/24\\Z`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbb"
              }}>{`Z`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`/24`}</span><span parentName="span" {...{
                "className": "mord mathbb"
              }}>{`Z`}</span></span></span></span></span>{` with the subgroup generated by
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`4`}</mn></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`4`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`4`}</span></span></span></span></span>{` via
group addition.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Coset`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Coset Elements`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Coset Size`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0 + H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[0, 4, 8, 12, 16, 20]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1 + H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[1, 5, 9, 13, 17, 21]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2 + H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[2, 6, 10, 14, 18, 22]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3 + H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[3, 7, 11, 15, 19, 23]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4 + H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`[0, 4, 8, 12, 16, 20]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Here are some things worth noticing about this table:`}</p>
    <ol>
      <li parentName="ol"><span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0`}</mn><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`0 + H`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.7278em",
                    "verticalAlign": "-0.0833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span></span></span></span></span>{` and `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`4`}</mn><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`4 + H`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.7278em",
                    "verticalAlign": "-0.0833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`4`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span></span></span></span></span>{` are the same! I left this in intentionally to point
out the fact that if we keep incrementing our added term to the `}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0`}</mn><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`0 + H`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.7278em",
                    "verticalAlign": "-0.0833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span></span></span></span></span>{`
coset, we eventually wrap around with what we started with! Is this a
coincidence or is this always the case?`}</li>
      <li parentName="ol">{`The size of all the cosets are the same. This makes sense because when we
have a subgroup and we add a number to it, it can be thought of as a
"translation" of all the elements, hence the sizes will all the same as
`}<span parentName="li" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mn parentName="mrow">{`0`}</mn><mo parentName="mrow">{`+`}</mo><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`0 + H`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.7278em",
                    "verticalAlign": "-0.0833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord"
                }}>{`0`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mbin"
                }}>{`+`}</span><span parentName="span" {...{
                  "className": "mspace",
                  "style": {
                    "marginRight": "0.2222em"
                  }
                }}></span></span><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.6833em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span></span></span></span></span>{`. But how do we know the elements of a coset of any subgroup have
no shared elements? If this were the case this could result in different
coset sizes!`}</li>
      <li parentName="ol">{`All the elements of the cosets contain all the elements of the group! Does
this mean that all the cosets split the group?`}</li>
    </ol>
    <p>{`It's not that obvious now, but proving that these observations are always
true will give us all the information needed to prove Lagranges Theorem!`}</p>
    <h2 {...{
      "id": "when-are-cosets-the-same"
    }}>{`When are cosets the same?`}</h2>
    <p>{`In our first observation we saw the same coset twice, this begs the question
when are any two cosets equal? We will show that if two cosets have a single
element in common, then all their elements will be in common, making them the
same. In terse math notation we can write this assumption as:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`→`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a \\in H b \\rightarrow H a = H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5782em",
                  "verticalAlign": "-0.0391em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span></div>
    <p>{`One of the most effective ways to show that two cosets are equal is by showing
that if each coset shares an element, then it's a subset of the other. Let's
break it down.`}</p>
    <p>{`The first thing we can observe is that if `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a \\in H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5782em",
                  "verticalAlign": "-0.0391em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`,
then there must exist an element `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`h_1`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` such that:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`b`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a = b h_{1}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`This means we can take our first coset `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span>{`, select a random element
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span></span></span></span></span>{` from
it, and rewrite it as `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`a`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x = a h_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`. By doing this and rewriting in terms
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span>{` like so:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`a`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`b`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`1`}</mn></msub><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mo parentName="mrow">{`∴`}</mo><mi parentName="mrow">{`b`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`1`}</mn></msub><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} x = a h_{2} \\\\ x = b h_{1} h_{2} \\\\ \\therefore b h_{1} h_{2} \\in H b \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "4.5em",
                  "verticalAlign": "-2em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.5em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-4.66em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.16em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.66em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mrel amsrm"
                              }}>{`∴`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`By doing this reduction, we know that any random element of
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span>{` is an element of
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`. Now we need to show
the inverse is true and that any random element of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` is an element of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span>{`.`}</p>
    <p>{`We can do this by leveraging the `}<a parentName="p" {...{
        "href": "https://explained-from-first-principles.com/number-theory/#group-axioms"
      }}>{`property of inverses`}</a>{` of groups to rewrite
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`b`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a = b h_{1}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8444em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` into `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi><msubsup parentName="mrow"><mi parentName="msubsup">{`h`}</mi><mn parentName="msubsup">{`1`}</mn><mrow parentName="msubsup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msubsup><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a  h_1^{-1} = b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.1205em",
                  "verticalAlign": "-0.2663em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`h`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8542em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.4337em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.1031em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`−`}</span><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2663em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`. With this we can show that for some `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`y \\in H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7335em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`y`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` the follow reduction can be done:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`b`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`3`}</mn></msub></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`a`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`3`}</mn></msub><msup parentName="mrow"><mi parentName="msup">{`h`}</mi><mrow parentName="msup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msup></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mo parentName="mrow">{`∴`}</mo><mi parentName="mrow">{`a`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`3`}</mn></msub><msup parentName="mrow"><mi parentName="msup">{`h`}</mi><mrow parentName="msup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msup><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`a`}</mi></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} y \\in H b \\\\ y = b h_{3} \\\\ y = a h_{3} {h^{-1}} \\\\ \\therefore a h_{3} {h^{-1}} \\in H a \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "6.0482em",
                  "verticalAlign": "-2.7741em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "3.2741em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-5.4341em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`y`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.9341em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`y`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`y`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal"
                                  }}>{`h`}</span><span parentName="span" {...{
                                    "className": "msupsub"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.8641em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.113em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`−`}</span><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`1`}</span></span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-0.8859em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mrel amsrm"
                              }}>{`∴`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`3`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal"
                                  }}>{`h`}</span><span parentName="span" {...{
                                    "className": "msupsub"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.8641em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.113em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`−`}</span><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`1`}</span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.7741em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`This implies that if we select any element from either coset, it must belong
to the other, implying that all the elements of one coset must be in the
other, or in other words, they are the same!`}</p>
    <h2 {...{
      "id": "why-cosets-are-equal-xor-disjoint"
    }}>{`Why cosets are equal xor disjoint?`}</h2>
    <p>{`We showed when cosets are equal, but in order to show our second observation
is always the case, we need to show that if two cosets don't share an element,
then they are disjoint (no shared elements). Luckily we can build on our
previous coset equality result to show this.`}</p>
    <p>{`To show that cosets are disjoint we will assume that the intersection of
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span>{` and
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{` is empty, but if there
is a shared element, they are equal. With this assumption we can do the
following:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`∩`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`a`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`a`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`1`}</mn></msub></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`b`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`=`}</mo><msubsup parentName="mrow"><mi parentName="msubsup">{`h`}</mi><mn parentName="msubsup">{`1`}</mn><mrow parentName="msubsup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msubsup><mi parentName="mrow">{`x`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`b`}</mi><msub parentName="mrow"><mi parentName="msub">{`h`}</mi><mn parentName="msub">{`2`}</mn></msub><msubsup parentName="mrow"><mi parentName="msubsup">{`h`}</mi><mn parentName="msubsup">{`1`}</mn><mrow parentName="msubsup"><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`1`}</mn></mrow></msubsup></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mo parentName="mrow">{`∴`}</mo><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} x \\in H b \\cap H a \\\\ x = a h_{1} \\\\ x = b h_{2} \\\\ a = {h_{1}^{-1}} x \\\\ a = b h_{2} {h_{1}^{-1}} \\\\ \\therefore a \\in H b \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "9.0482em",
                  "verticalAlign": "-4.2741em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "4.7741em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-6.9341em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mbin"
                              }}>{`∩`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-5.4341em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.9341em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.41em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal"
                                  }}>{`h`}</span><span parentName="span" {...{
                                    "className": "msupsub"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.8641em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.4436em",
                                              "marginLeft": "0em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`1`}</span></span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.113em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`−`}</span><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.2564em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`x`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-0.8859em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`h`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal"
                                  }}>{`h`}</span><span parentName="span" {...{
                                    "className": "msupsub"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-t vlist-t2"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.8641em"
                                          }
                                        }}><span parentName="span" {...{
                                            "style": {
                                              "top": "-2.4436em",
                                              "marginLeft": "0em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`1`}</span></span></span></span><span parentName="span" {...{
                                            "style": {
                                              "top": "-3.113em",
                                              "marginRight": "0.05em"
                                            }
                                          }}><span parentName="span" {...{
                                              "className": "pstrut",
                                              "style": {
                                                "height": "2.7em"
                                              }
                                            }}></span><span parentName="span" {...{
                                              "className": "sizing reset-size6 size3 mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`−`}</span><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                          "className": "vlist-s"
                                        }}>{`​`}</span></span><span parentName="span" {...{
                                        "className": "vlist-r"
                                      }}><span parentName="span" {...{
                                          "className": "vlist",
                                          "style": {
                                            "height": "0.2564em"
                                          }
                                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "0.6141em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mrel amsrm"
                              }}>{`∴`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`a`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`b`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "4.2741em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`Here we show that if we pull an element from the intersection of two cosets,
the coset generated by `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span></span></span></span></span>{`
must be in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`. But this
should look familiar because we just proved above that if `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`a \\in H b`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5782em",
                  "verticalAlign": "-0.0391em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`b`}</span></span></span></span></span>{`, then both cosets
must be equal! This implies that if there are shared elements between both
cosets, they must be equal which also implies if they don't have common
element(s), they have nothing in common.`}</p>
    <h2 {...{
      "id": "why-do-cosets-split-the-group"
    }}>{`Why do cosets split the group?`}</h2>
    <p>{`Our last observation to prove is that all cosets of a group split the
group. To show this is true, we just need to ensure that every element
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`g`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`g \\in G`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.7335em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`∈`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span></span></span></span></span>{`
belongs to some coset `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`g`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H g`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8778em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span></span></span></span></span>{`. Luckily this is simple to show:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`e`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`g`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`g`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`g`}</mi><mo parentName="mrow">{`∈`}</mo><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`g`}</mi></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} e \\in H \\\\ e g \\in H g \\\\ g \\in H g \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "4.5em",
                  "verticalAlign": "-2em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.5em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-4.66em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`e`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.16em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`e`}</span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`g`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`g`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.66em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`g`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`∈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`g`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`Since `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span></span></span></span></span>{` is a subgroup, it
must have an identity element. Then we can create the coset `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mi parentName="mrow">{`g`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H g`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8778em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span></span></span></span></span>{` of which `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`g`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`e g`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span></span></span></span></span>{` must be an element. And since applying the group
operation with the identity element returns the applied element, we know that `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`g`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`g`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`g`}</span></span></span></span></span>{` will always belong the coset it generates,
meaning every element in `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span></span></span></span></span>{` belongs to a coset.`}</p>
    <h2 {...{
      "id": "lagranges-theorem"
    }}>{`Lagrange's Theorem`}</h2>
    <p>{`Now that we have all the component information for Lagrange's Theorem, we can
move forward in proving it. We showed in the beginning that the theorem can be
stated as:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi><mo parentName="mrow">{`≤`}</mo><mi parentName="mrow">{`G`}</mi><mo parentName="mrow">{`→`}</mo><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`o`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`r`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`d`}</mi></mrow><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`(`}</mo><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`)`}</mo></mrow><mtext parentName="mrow">{` `}</mtext><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mtext parentName="mrow">{` `}</mtext><mrow parentName="mrow"><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`o`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`r`}</mi><mi parentName="mrow" {...{
                      "mathvariant": "normal"
                    }}>{`d`}</mi></mrow><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`(`}</mo><mi parentName="mrow">{`G`}</mi><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`)`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H \\leq G \\rightarrow {\\rm ord}\\left(H\\right) \\space | \\space {\\rm ord}\\left(G\\right)`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.8193em",
                  "verticalAlign": "-0.136em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`≤`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`→`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`ord`}</span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord mathnormal",
                  "style": {
                    "marginRight": "0.08125em"
                  }
                }}>{`H`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`)`}</span></span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mord mathrm"
                  }}>{`ord`}</span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`(`}</span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`G`}</span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}>{`)`}</span></span></span></span></span></span></div>
    <p>{`To reach this conclusion we will start with our previous result that all the
cosets split the group, meaning by "unioning" all the cosets we reconstruct
the original group:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow">{`G`}</mi><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`H`}</mi><msub parentName="mrow"><mi parentName="msub">{`a`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow">{`∪`}</mo><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`.`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`.`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`.`}</mi><mo parentName="mrow">{`∪`}</mo><mi parentName="mrow">{`H`}</mi><msub parentName="mrow"><mi parentName="msub">{`a`}</mi><mi parentName="msub">{`n`}</mi></msub></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} G = H a_{1} \\cup ... \\cup H a_{n} \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.5em",
                  "verticalAlign": "-0.5em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.16em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`G`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`a`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mbin"
                              }}>{`∪`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`...`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mbin"
                              }}>{`∪`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal",
                                "style": {
                                  "marginRight": "0.08125em"
                                }
                              }}>{`H`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`a`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.1514em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mathnormal mtight"
                                              }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.5em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`Keep in mind there will be some integer `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4306em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`n`}</span></span></span></span></span>{` number of cosets. If we transition to thinking
about orders, then the total order of the group will be the sum of the number
of elements of the cosets:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo><mi parentName="mrow">{`G`}</mi><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo><mi parentName="mrow">{`H`}</mi><msub parentName="mrow"><mi parentName="msub">{`a`}</mi><mn parentName="msub">{`1`}</mn></msub><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo></mrow><mo parentName="mrow">{`+`}</mo><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`.`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`.`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`.`}</mi><mo parentName="mrow">{`+`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo><mi parentName="mrow">{`H`}</mi><msub parentName="mrow"><mi parentName="msub">{`a`}</mi><mi parentName="msub">{`n`}</mi></msub><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo></mrow></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} {\\left| G \\right|} = {\\left| H a_{1} \\right|} + ... + {\\left| H a_{n} \\right|} \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.5em",
                  "verticalAlign": "-0.5em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.16em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "minner"
                                }}><span parentName="span" {...{
                                    "className": "mopen delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal"
                                  }}>{`G`}</span><span parentName="span" {...{
                                    "className": "mclose delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span></span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "minner"
                                }}><span parentName="span" {...{
                                    "className": "mopen delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal",
                                    "style": {
                                      "marginRight": "0.08125em"
                                    }
                                  }}>{`H`}</span><span parentName="span" {...{
                                    "className": "mord"
                                  }}><span parentName="span" {...{
                                      "className": "mord mathnormal"
                                    }}>{`a`}</span><span parentName="span" {...{
                                      "className": "msupsub"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.3011em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.55em",
                                                "marginLeft": "0em",
                                                "marginRight": "0.05em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "2.7em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "sizing reset-size6 size3 mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}><span parentName="span" {...{
                                                    "className": "mord mtight"
                                                  }}>{`1`}</span></span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.15em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                    "className": "mclose delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span></span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mbin"
                              }}>{`+`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`...`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mbin"
                              }}>{`+`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2222em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "minner"
                                }}><span parentName="span" {...{
                                    "className": "mopen delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal",
                                    "style": {
                                      "marginRight": "0.08125em"
                                    }
                                  }}>{`H`}</span><span parentName="span" {...{
                                    "className": "mord"
                                  }}><span parentName="span" {...{
                                      "className": "mord mathnormal"
                                    }}>{`a`}</span><span parentName="span" {...{
                                      "className": "msupsub"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.1514em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-2.55em",
                                                "marginLeft": "0em",
                                                "marginRight": "0.05em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "2.7em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "sizing reset-size6 size3 mtight"
                                              }}><span parentName="span" {...{
                                                  "className": "mord mtight"
                                                }}><span parentName="span" {...{
                                                    "className": "mord mathnormal mtight"
                                                  }}>{`n`}</span></span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.15em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                                    "className": "mclose delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.5em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`But since we know that all cosets of the subgroup are the same size as the
subgroup, we can rewrite this as:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo><mi parentName="mrow">{`G`}</mi><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo></mrow><mo parentName="mrow">{`=`}</mo><mi parentName="mrow">{`n`}</mi><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo><mi parentName="mrow">{`H`}</mi><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`∣`}</mo></mrow></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{alignedat}{2} {\\left| G \\right|} = n {\\left| H \\right|} \\end{alignedat}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.5em",
                  "verticalAlign": "-0.5em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-3.16em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "minner"
                                }}><span parentName="span" {...{
                                    "className": "mopen delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal"
                                  }}>{`G`}</span><span parentName="span" {...{
                                    "className": "mclose delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span></span></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`n`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "minner"
                                }}><span parentName="span" {...{
                                    "className": "mopen delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span><span parentName="span" {...{
                                    "className": "mord mathnormal",
                                    "style": {
                                      "marginRight": "0.08125em"
                                    }
                                  }}>{`H`}</span><span parentName="span" {...{
                                    "className": "mclose delimcenter",
                                    "style": {
                                      "top": "0em"
                                    }
                                  }}>{`∣`}</span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "0.5em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`And we're done! This shows that the order of a group `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`G`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`G`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`G`}</span></span></span></span></span>{` is a multiple of the order of a subgroup
`}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`H`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`H`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal",
                "style": {
                  "marginRight": "0.08125em"
                }
              }}>{`H`}</span></span></span></span></span>{` which is functionally
the same as saying the order of the subgroup divides the order of the group!`}</p>
    <p>{`This incredible result attributed to Lagrange means that just by knowing the
order of a group allows us to infer information about the order of subgroups
(and vice versa). But one of the most important details to keep in mind about
this proof is that just because the order of a subgroup must divide the order
of the group, it doesn't guarantee a subgroup of a dividing order exists. In
essence this is a "one way" proof stating that any subgroups that exist will
divide the order of the group.`}</p>
    <h2 {...{
      "id": "downstream-implications"
    }}>{`Downstream implications`}</h2>
    <p>{`This theorem has large implications for the rest of group theory. For example
if we consider groups that are of prime order `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`p`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`p`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.1944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathnormal"
              }}>{`p`}</span></span></span></span></span>{` ... well by definition nothing divides it except
itself and one, so the only subgroups that can exist are the identity element
and the group itself! This type of group has a special name, a "cyclic" group
because any non identity element of the group can generate the rest of the
group.`}</p>
    <p>{`There are also many practical applications of Lagrange's Theorem. For example
in the `}<a parentName="p" {...{
        "href": "https://zkhack.dev/puzzles/"
      }}>{`ZKHACK 2021 hackathon`}</a>{` in `}<a parentName="p" {...{
        "href": "https://hackmd.io/@shalevos/HyDgqfBPK"
      }}>{`the second puzzle`}</a>{`, participants need to
leverage Lagrange's Theorem to break the discrete log of an unsafe group with small
prime cofactors.`}</p>
    <p>{`Thanks for reading! Stay tuned for breakdowns of more group theory theorems
like the primitive root theorem, and maybe more regarding implementing ECC
pairings 😮!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      